


















































































































































































































import Vue from 'vue'
import {
  mapState,
  mapActions
} from 'vuex'
import {
  yandexMap,
  ymapMarker,
  loadYmap
} from '@/plugins/YMapPlugin'
import Search from '@/components/Search/Search.vue'
import Panel from './components/Panel.vue'
import { Area } from '@/store/modules/areas/types'
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import { cloneDeep } from 'lodash'
import { handleErrors } from '@/utils/functions'
import Loading from '@/components/Loading/index.vue'
import { TheMask } from 'vue-the-mask'
declare const ymaps: any

export default Vue.extend({
  name: 'Areas',

  components: {
    yandexMap,
    ymapMarker,
    Search,
    Panel,
    TheMask,
    VSwatches,
    Loading
  },

  data () {
    return {
      showOrganizations: true,
      drawingPolygon: null,
      profilesLoading: false,
      hoveredAreaId: null,
      drawing: false,
      startCreateArea: false,
      initialArea: {
        id: null,
        name: '',
        color: '#ff4500',
        shapes: [{
          geometry: []
        }]
      },
      editingArea: { id: null },
      map: null,
      searchValue: '',
      viewedArea: {} as Area,
      viewedAreaBusinesses: [],
      businessesList: [],
      panelVisible: true,
      settings: {
        apiKey: '5e4300dc-6a38-4d6e-b29a-382b9bc41e71',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1'
      },
      clusterOptions: {
        1: {
          // clusterDisableClickZoom: true,
          clusterIconColor: '#000000',
          clusterOpenBalloonOnClick: true,
          clusterBalloonLayout: [
            '<ul class=list>',
            '{% for geoObject in properties.geoObjects %}',
            '<li><a href=# class="list_item">{{ geoObject.properties.balloonContentHeader|raw }}</a></li>',
            '{% endfor %}',
            '</ul>'
          ].join('')
        }
      }
    }
  },

  watch: {
    hoveredAreaId (id: string) {
      if (!id) {
        this.resetHighlightAllGeoObjects()
        return
      }

      const index = this.areas.findIndex(item => item.id === id)
      const hoveredGeoObject = this.polygons[index]

      this.highlightGeoObject(hoveredGeoObject)
    },

    viewedArea (area: Area) {
      if (area.id) {
        this.getBusinessProfilesByAreaId(area.id)
        // this.hideAllPolygonsNotCurrent()
      } else {
        this.viewedAreaBusinesses = []
        // this.showAllPolygons()
      }
    }
  },

  computed: {
    ...mapState('areas', [
      'areas'
    ]),

    filteredAreas () {
      const search = this.searchValue.toLowerCase().trim()

      if (!search) return this.areas

      return this.areas.filter(item => item.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1)
    },

    polygons () {
      const polygonsArray = []

      this.map.geoObjects.each((geoObject: any) => {
        if (geoObject.geometry) {
          if (geoObject.geometry.getType() === 'Polygon') {
            polygonsArray.push(geoObject)
          }
        }
      })
      return polygonsArray
    },

    editingPolygon () {
      const index = this.areas.findIndex(item => item.id === this.editingArea.id)
      return this.polygons[index]
    }
  },

  async mounted () {
    await this.getAllAreas()
    await this.getAllBusinessProfiles()

    await loadYmap({
      ...this.settings,
      debug: true
    })
  },

  methods: {
    ...mapActions('areas', [
      'getAreas',
      'createArea',
      'updateArea',
      'deleteArea',
      'getBusinessProfiles'
    ]),

    balloonTemplate (businessProfile: any) {
      return `
        <div class="business-profile business-profile--baloon">
          <div class="business-profile__avatar" style="background-image: url(${businessProfile.avatar_url})"></div>
          <div class="business-profile__username">@${businessProfile.username}</div>
          <div class="business-profile__category">${businessProfile.profile_data.category.name}</div>
          <div class="business-profile__phone">${businessProfile.profile_data.contact_phone}</div>
          <a class="link" href="/profiles/${businessProfile.id}" target="_blank">Посмотреть профиль</a>
        </div>
      `
    },

    getProfileAvatarUrl (url: string) {
      if (!url) return null
      if (url.indexOf('default_avatar') !== -1) return null

      return url
    },

    markerIcon (businessProfile: any) {
      // const circleLayout = ymaps.templateLayoutFactory.createClass('<div class="placemark_layout_container"><div class="circle_layout">#</div></div>')
      const iconContentLayout = `<div class="marker-avatar" style="background-image: url(${this.getProfileAvatarUrl(businessProfile.avatar_url)})"></div>`

      return {
        layout: 'default#imageWithContent',
        imageHref: require('@/assets/images/icon-marker.png'),
        // imageHref: businessProfile.avatar_url,
        imageSize: [72, 81],
        // layout: circleLayout,
        imageOffset: [-36, -64],
        contentLayout: iconContentLayout,
        iconShape: {
          type: 'Circle',
          coordinates: [0, 0],
          radius: 30
        }
        // content: businessProfile.username,
        // contentOffset: [0, 15],
        // contentLayout: '<div style="background: red; width: 50px; color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
      }
    },

    async getBusinessProfilesByAreaId (areaId: string = null) {
      if (!areaId) areaId = this.viewedArea.id

      try {
        this.profilesLoading = true
        const res = await this.getBusinessProfiles(areaId) // this.viewedArea.id

        if (this.viewedArea.id) {
          this.viewedAreaBusinesses = res
        } else {
          this.businessesList = [...this.businessesList, ...res]
        }
        // this.addMarkersForViewedArea()
      } catch {
        this.$toast.error('Произошла ошибка при получении списка бизнесов')
      } finally {
        this.profilesLoading = false
      }
    },

    // addMarkersForViewedArea () {
    //   this.viewedAreaBusinesses.forEach(item => {
    //     this.addMarker(item)
    //   })
    // },

    addMarker (businessProfile) {
      const geoPoint = businessProfile.location.geo_point
      const placemark = new ymaps.Placemark([geoPoint.latitude, geoPoint.longitude], {
        balloonContent: businessProfile.location.address
        // iconContent: businessProfile.location.address
      }, {
        hideIconOnBalloonOpen: false,
        // Необходимо указать данный тип макета.
        iconLayout: 'default#image',
        // Своё изображение иконки метки.
        iconImageHref: require('@/assets/images/icon-marker.png'),
        // Размеры метки.
        iconImageSize: [73, 81],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [-36, -64]
      })
      this.map.geoObjects.add(placemark)
    },

    hideAllPolygonsNotCurrent () {
      const index = this.areas.findIndex(item => item.id === this.viewedArea.id)

      if (!this.polygons.length) return
      this.polygons.forEach((item, itemIndex) => {
        if (index !== itemIndex) {
          item.options.set({
            visible: false
          })
        }
      })
    },

    showAllPolygons () {
      if (!this.polygons.length) return
      this.polygons.forEach((item, itemIndex) => {
        item.options.set({
          visible: true
        })
      })
    },

    highlightGeoObject (geoObject: any) {
      this.resetHighlightAllGeoObjects()

      geoObject.options.set({
        // fillOpacity: 0.8,
        strokeWidth: 6
      })
    },

    resetHighlightAllGeoObjects () {
      if (!this.polygons.length) return
      this.polygons.forEach(item => {
        item.options.set({
          fillOpacity: 0.35,
          strokeWidth: 2
        })
      })
    },

    async getAllAreas () {
      try {
        this.loading = true
        await this.getAreas()
      } catch {
        this.$toast.error('Произошла ошибка при получении периметров')
      } finally {
        this.loading = false
      }
    },

    async saveDrawingArea () {
      try {
        this.loading = true
        const res = await this.createArea(cloneDeep(this.editingArea))
        this.$toast.success('Периметр добавлен')
        this.updateGeoObjectAreaForCreatedArea(res.id)
        this.startCreateArea = false
        this.drawing = false
        this.disableAllPolygonsEditing()
      } catch (error) {
        handleErrors(error)
        // this.$toast.error('Произошла ошибка при создании периметра')
      } finally {
        this.loading = false
      }
    },

    async updateEditingArea () {
      try {
        this.loading = true
        this.saveCoordinates()
        await this.updateArea(this.editingArea)
        this.updateFillStrokeColorsForEditingArea()
        this.resetEditingArea()
        this.$toast.success('Периметр обновлен')
      } catch (e) {
        this.$toast.error('Произошла ошибка при обновлении периметра')
      } finally {
        this.loading = false
      }
    },

    updateFillStrokeColorsForEditingArea () {
      this.editingPolygon.options.set({
        fillColor: this.editingArea.color,
        strokeColor: this.editingArea.color
      })
    },

    updateGeoObjectAreaForCreatedArea (areaId: string) {
      this.polygons[this.polygons.length - 1].properties.set({
        areaId: areaId
      })

      this.polygons[this.polygons.length - 1].events.add('mouseenter', () => {
        this.hoveredAreaId = areaId
      })

      this.polygons[this.polygons.length - 1].events.add('mouseleave', () => {
        this.hoveredAreaId = null
      })
    },

    removeGeoObjectByIndex (index: number) {
      const geoobject = this.polygons[index]
      this.map.geoObjects.remove(geoobject)
    },

    async handleDeleteArea () {
      try {
        this.loading = true
        const index = this.areas.findIndex(item => item.id === this.editingArea.id)
        await this.deleteArea(this.editingArea.id)
        this.$toast.success('Периметр удален')
        this.removeGeoObjectByIndex(index)
        this.resetEditingArea()
      } catch {
        this.$toast.error('Произошла ошибка при удалении периметра')
      } finally {
        this.loading = false
      }
    },

    cancelCreateArea () {
      this.startCreateArea = false
      this.drawing = false
      this.editingArea = cloneDeep(this.initialArea)
      this.removeDrawingPolygon()
    },

    handleCreateArea () {
      this.startCreateArea = true
      this.editingArea = cloneDeep(this.initialArea)
      setTimeout(() => {
        this.$refs['new-area-name-input'].$el.focus()
      }, 100)
    },

    ymapInitialized (map) {
      this.map = map
      this.renderAreas()
    },

    async getAllBusinessProfiles () {
      try {
        this.profilesLoading = true
        const res = await this.getBusinessProfiles()

        this.businessesList = res
      } catch {
        this.$toast.error('Произошла ошибка при получении списка бизнесов')
      } finally {
        this.profilesLoading = false
      }

      // this.areas.forEach((area: Area) => {
      //   this.getBusinessProfilesByAreaId(area.id)
      // })
    },

    renderAreas () {
      if (!this.areas.length) return

      this.areas.map(area => {
        this.addPolygon(area)
        return area
      })
    },

    getCoordinates (areaFirstShapeGeometry) {
      return areaFirstShapeGeometry.map(item => [item.latitude, item.longitude])
    },

    addPolygon (area) {
      const coordinates = this.getCoordinates(area.shapes[0].geometry)

      const polygon = new ymaps.Polygon([coordinates], {
        hintContent: area.name,
        areaId: area.id
      }, {
        fillColor: area.color,
        fillOpacity: 0.35,
        strokeColor: area.color,
        strokeWidth: 2,
        editorMenuManager: (t) => {
          return t.filter((t) => {
            return t.id !== 'addInterior'
          })
        }
      })

      this.map.geoObjects.add(polygon)

      polygon.events.add('click', () => {
        if (this.startCreateArea) return
        this.handleClickPolygon(polygon)
      })

      polygon.events.add('mouseenter', () => {
        if (this.viewedArea.id || this.startCreateArea) return
        this.hoveredAreaId = area.id
      })

      polygon.events.add('mouseleave', () => {
        this.hoveredAreaId = null
      })

      polygon.geometry.events.add('change', () => {
        this.saveCoordinates(polygon)
      })
    },

    handleClickPolygon (polygon) {
      this.disableAllPolygonsEditing()
      this.editPolygon(polygon)

      const editingAreaId = polygon.properties.get('areaId')
      this.editingArea = this.areas.find(item => item.id === editingAreaId)
    },

    disableAllPolygonsEditing () {
      if (!this.polygons.length) return
      this.polygons.forEach((item: any) => {
        item.editor.stopEditing()
      })
    },

    editPolygon (polygon) {
      this.panelVisible = true
      polygon.editor.startEditing()
    },

    enableDrawingArea () {
      this.disableAllPolygonsEditing()
      this.drawing = true

      if (!this.editingArea.id) this.addPolygon(this.editingArea)

      let index = this.polygons.length - 1
      if (this.editingArea.id) {
        index = this.areas.findIndex(item => item.id === this.editingArea.id)
      }

      const geoobject = this.polygons[index]

      if (this.editingArea.id) {
        geoobject.editor.startEditing()
      } else {
        geoobject.editor.startDrawing()
      }

      geoobject.editor.events.add('drawingstop', (e) => {
        this.saveCoordinates(geoobject)
      })
    },

    saveCoordinates (geoobject: any) {
      if (!geoobject) {
        const index = this.areas.findIndex(item => item.id === this.editingArea.id)
        geoobject = this.polygons[index]
      }
      const coordinates = geoobject.geometry.getCoordinates()
      this.editingArea.shapes[0].geometry = coordinates[0]
    },

    removeDrawingPolygon () {
      this.map.geoObjects.remove(this.editingPolygon)
    },

    setViewedArea (area: Area) {
      this.viewedArea = area
    },

    handleEditArea () {
      this.editingArea = this.viewedArea
      this.hoveredAreaId = null
      this.viewedArea = { id: null }
      this.enableDrawingArea()
    },

    resetEditingArea () {
      this.startCreateArea = false
      this.editingArea = cloneDeep(this.initialArea)
      this.disableAllPolygonsEditing()
    },

    onCloseCreateEditingPanel () {
      if (this.editingArea.id) {
        this.resetEditingArea()
        return
      }

      this.cancelCreateArea()
    }
  }
})
