


























import { Area } from '@/store/modules/areas/types'
import Vue, { PropType } from 'vue'
export default Vue.extend({
  name: 'Panel',

  props: {
    isActive: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    showEditBtn: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    area: {
      type: Object as PropType<Area>,
      default: () => ({} as Area)
    },
    isHome: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    title: {
      type: String as PropType<string>,
      default: null
    }
  }
})
